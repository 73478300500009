import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import randomColor from 'randomcolor';
import { Container, Grid, Card, TextField, Button, Checkbox, Box, Typography, Switch, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { evaluationTeamsActions, ideaStepFluxActions } from '../../actions';
import { BackNavAdmin, LoadingButton, SkeletonIdeaStepFluxCard } from '../../components';
import { MultiSelect } from '../../components';
import { useStyles } from './styles';
import { theme } from '../../theme';
import { FluxOfIdeaConfirmModal } from '../FluxOfIdeasPage';

function IdeaStepFluxNewPage() {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [confirmEditModal, setConfirmEditModal] = useState(false);
  const ideaStepFlux = useSelector((state) => state.ideaStepFlux);
  const { teams } = useSelector(state => state.evaluationTeams);
  const { ideasStepsFlux } = useSelector((state) => state.domains);
  const isEdit = id !== undefined && id !== null;
  const stepData = ideasStepsFlux.some(item => item._id === id);
  const teamsFiltered = teams.filter(item => ideaStepFlux.evaluationTeams.includes(item._id))

  useEffect(() => {
    return () => {
      dispatch(ideaStepFluxActions.clearIdeaStepFluxData())
    }
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && stepData) {
      dispatch(ideaStepFluxActions.loadStepDetails(ideasStepsFlux.filter(item => item._id === id)[0]));
    }
  }, [isEdit, ideasStepsFlux, stepData]);

  useEffect(() => {
    if (ideasStepsFlux && ideasStepsFlux.length > 0) {
      dispatch(ideaStepFluxActions.setIdeaStepFluxData({
        ...ideaStepFlux,
        ideaFlux: ideasStepsFlux[0].ideaFlux,
        labelColor: randomColor()
      }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(evaluationTeamsActions.getAllEvaluationTeams());
  }, []);

  function handleSend() {
    if (ideaStepFlux.returnTheIdeaToAnotherStage && !ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepPendingReview')[0].title) return;

    delete ideaStepFlux.returnTheIdeaToAnotherStage;
    delete ideaStepFlux.loadingStepDetails;
    delete ideaStepFlux.decisionTitle;
    
    ideaStepFlux.decisions = ideaStepFlux.decisions.filter(item => {
      if (item.typeDecision === 'NextStepApproved' && !item.title) return false;
      if (item.typeDecision === 'NextStepRejected' && !item.title) return false;
      return true;
    });
    
    ideaStepFlux.decisions.forEach(item => {
      if (item.typeDecision === 'NextStepPendingReview' && !item.notificationMessagePendingReview) {
        delete item.notificationMessagePendingReview;
      }
    });

    if (isEdit) {
      dispatch(ideaStepFluxActions.editIdeaStepFlux(ideaStepFlux));
      setConfirmEditModal(false);
    } else {
      dispatch(ideaStepFluxActions.createIdeaStepFlux(ideaStepFlux));
    }
  }

  function onChangeText({ target: { value, name } }) {
    dispatch(ideaStepFluxActions.setNewContent({ name, value }));
  }

  function onChangeSwitch({ target: { checked, name } }) {
    dispatch(ideaStepFluxActions.setNewContentSwitch({ name, checked }));
  }

  function onChangeSwitchEvaluation({ target: { checked, name } }) {
    dispatch(ideaStepFluxActions.setEvaluationLevel({ name, checked }));
  }

  function onChangeSwitchNotification({ target: { checked, name } }) {
    dispatch(ideaStepFluxActions.setNotificationControls({ name, checked }));
  }

  function onChangeVisualization({ target: { value } }) {
    dispatch(ideaStepFluxActions.setVisualizationConfigStep({ name: value }));
  }

  function onChangeSwitchDefault({ target: { checked, name } }) {
    dispatch(ideaStepFluxActions.setIdeaContributorsCanApproveStep({ name, checked }));
  }

  function onChangeDecisionText({ target: { value, name } }) {
    dispatch(ideaStepFluxActions.setDecisionName({ typeDecision: name, value }));
  }

  function onChangeDecisionNotification({ target: { value } }) {
    dispatch(ideaStepFluxActions.setDecisionNotification({ value }));
  }

  function handleTeamsSelect(event) {
    dispatch(ideaStepFluxActions.setNewContent({ name: 'evaluationTeams', value: event }));
  }

  function onChangeNewDecision({ target: { checked } }) {
    let newDecision = {
      title: ideaStepFlux.decisionTitle,
      typeDecision: "NextStepPendingReview",
      _id: "64a5b05ba49e538ee36dfd03",
      icon: "arrow_forward",
      notificationMessagePendingReview: ideaStepFlux?.notificationMessagePendingReview
    }

    dispatch(ideaStepFluxActions.pushNewDecision({ checked, newDecision }));
  }

  function validateDisabled () {
    const validateFieldReturnTheIdeaToAnotherStage = ideaStepFlux.returnTheIdeaToAnotherStage && !ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepPendingReview')[0]?.title;
    return ideaStepFlux.loading || !ideaStepFlux.name || validateFieldReturnTheIdeaToAnotherStage;
  }

  const CheckboxCustom = ({ name, checked, onChange, label }) => (
    <Box className={classes.checkbox}>
      <Checkbox
        name={name}
        onChange={onChange}
        checked={checked}
        color="primary"
      />
      <Typography className={classes.checkboxText}>{label}</Typography>
    </Box>
  );

  return (
    <>
      <BackNavAdmin title={isEdit ? t('update_idea_step_flux') : t('new_idea_step_flux')} pathname="/preferences/flux-of-ideas" />
      <FluxOfIdeaConfirmModal
        isDelete={false}
        open={confirmEditModal}
        handleClose={() => setConfirmEditModal(false)}
        handleConfirm={handleSend}
      />
      <Container maxWidth="lg" className={classes.root}>
        {isEdit && ideaStepFlux.loadingStepDetails && <SkeletonIdeaStepFluxCard />}

        {(!isEdit || (isEdit && !ideaStepFlux.loadingStepDetails)) && (
          <>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    label={t('step_name')}
                    type="text"
                    fullWidth
                    variant="filled"
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    value={ideaStepFlux.name}
                    onChange={onChangeText}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('arquivos_necessarios_aprovacao')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.attachmentsRequired}
                      onChange={onChangeSwitch}
                      name="attachmentsRequired"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.attachmentsRequired ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('opcao_ideia_timeline')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.optionIdeiaInTimeline}
                      onChange={onChangeSwitch}
                      name="optionIdeiaInTimeline"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.optionIdeiaInTimeline ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('aprovacao_na_etapa')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.approveInStepConcludeIdea}
                      onChange={onChangeSwitch}
                      name="approveInStepConcludeIdea"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.approveInStepConcludeIdea ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('reprovacao_na_etapa')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.rejectInStepConcludeIdea}
                      onChange={onChangeSwitch}
                      name="rejectInStepConcludeIdea"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.rejectInStepConcludeIdea ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('colaborador_editar_ideia')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.canEditIdea}
                      onChange={onChangeSwitch}
                      name="canEditIdea"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.canEditIdea ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    id="NextStepApproved"
                    name="NextStepApproved"
                    label={t('nome_botao_aprovacao')}
                    type="text"
                    fullWidth
                    variant="filled"
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    value={ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepApproved')[0]?.title || ''}
                    onChange={onChangeDecisionText}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="NextStepRejected"
                    name="NextStepRejected"
                    label={t('nome_botao_reprovar')}
                    type="text"
                    fullWidth
                    variant="filled"
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                      },
                    }}
                    value={ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepRejected')[0]?.title || ''}
                    onChange={onChangeDecisionText}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CheckboxCustom
                    name="returnTheIdeaToAnotherStage"
                    checked={ideaStepFlux.returnTheIdeaToAnotherStage}
                    onChange={onChangeNewDecision}
                    label={t('exibir_opcao_para_que_o_avaliador_possa_retornar_a_ideia_para_outra_etapa')}
                  />
                </Grid>
                
                {ideaStepFlux.returnTheIdeaToAnotherStage && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        name="NextStepPendingReview"
                        label={t('digite_um_nome_para_o_botao_que_ira_representar_a_acao_de_voltar_etapa')}
                        type="text"
                        fullWidth
                        variant="filled"
                        InputLabelProps={{ classes: { root: classes.labelRoot } }}
                        value={ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepPendingReview')[0]?.title || ''}
                        onChange={onChangeDecisionText}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="notificationMessagePendingReview"
                        label={t('defina_a_mensagem_que_sera_enviada_para_o_colaborador_caso_a_acao_de_voltar_etapa_seja_tomada')}
                        type="text"
                        fullWidth
                        variant="filled"
                        InputLabelProps={{ classes: { root: classes.labelRoot } }}
                        value={ideaStepFlux.decisions.filter(item => item.typeDecision === 'NextStepPendingReview')[0]?.notificationMessagePendingReview || ''}
                        onChange={onChangeDecisionNotification}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography
                      className={classes.stateTitle}
                      style={{ color: ideaStepFlux.returnTheIdeaToAnotherStage && theme.palette.neutrals.low.lightest }}
                    >
                      {t('preenchimento_da_execucao_com_prazos_custos_retornos_estimados_e_necessario')}
                    </Typography>
                    <Switch
                      disabled={ideaStepFlux.returnTheIdeaToAnotherStage}
                      checked={ideaStepFlux.ideaExecutionIsRequired}
                      onChange={onChangeSwitch}
                      name="ideaExecutionIsRequired"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.ideaExecutionIsRequired ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography
                      className={classes.stateTitle}
                      style={{ color: ideaStepFlux.returnTheIdeaToAnotherStage && theme.palette.neutrals.low.lightest }}
                    >
                      {t('preenchimento_da_finalizacao_da_execucao_com_prazos_custos_retornos_reais_e_necessario')}
                    </Typography>
                    <Switch
                      disabled={ideaStepFlux.returnTheIdeaToAnotherStage}
                      checked={ideaStepFlux.ideaFinishExecutionIsRequired}
                      onChange={onChangeSwitch}
                      name="ideaFinishExecutionIsRequired"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.ideaFinishExecutionIsRequired ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.stateContainer}>
                    <Typography className={classes.stateTitle}>
                      {t('exibir_resultados_da_ideia_nesta_etapa')}
                    </Typography>
                    <Switch
                      checked={ideaStepFlux.showResultsInStep}
                      onChange={onChangeSwitch}
                      name="showResultsInStep"
                      color="primary"
                      size="small"
                    />
                    <Typography className={classes.state}>
                      {ideaStepFlux.showResultsInStep ? t('sim') : t('nao')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Grid container className={classes.marginTopHigh}>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <Typography className={classes.stateTitle}>
                    {t('selecione_quem_pode_avaliar_nesta_etapa')}
                  </Typography>
                  <CheckboxCustom
                    name="canDirectManagerEvaluateStep"
                    checked={ideaStepFlux.evaluationLevel?.canDirectManagerEvaluateStep}
                    onChange={onChangeSwitchEvaluation}
                    label={t('gestor_direto_pode_avaliar')}
                  />
                  <CheckboxCustom
                    name="canAreaManagerEvaluateStep"
                    checked={ideaStepFlux.evaluationLevel.canAreaManagerEvaluateStep}
                    onChange={onChangeSwitchEvaluation}
                    label={t('gestor_area_pode_avaliar')}
                  />
                  <CheckboxCustom
                    name="canOriginAreaManagerEvaluateStep"
                    checked={ideaStepFlux.evaluationLevel.canOriginAreaManagerEvaluateStep}
                    onChange={onChangeSwitchEvaluation}
                    label={t('gestor_area_origem_pode_avaliar')}
                  />
                  <CheckboxCustom
                    name="teamAndAreaManagerEvaluateStep"
                    checked={ideaStepFlux.evaluationLevel.teamAndAreaManagerEvaluateStep}
                    onChange={onChangeSwitchEvaluation}
                    label={t('time_de_avaliacao_e_gestor_area')}
                  />
                  <CheckboxCustom
                    name="ideaContributorsCanApproveStep"
                    checked={ideaStepFlux.ideaContributorsCanApproveStep}
                    onChange={onChangeSwitchDefault}
                    label={t('colaborador_pode_avaliar')}
                  />
                  <Box className={classes.checkbox}>
                    <Typography className={classes.checkboxText}>
                      {t('time_de_avaliacao_aprovacao')}
                    </Typography>

                    <MultiSelect items={teams} defaultValues={teamsFiltered} id='teams' onChange={handleTeamsSelect}/>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Grid container className={classes.marginTopHigh}>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <Typography className={classes.stateTitle}>
                    {t('onde_a_etapa_devera_aparecer_para_ser_avaliada')}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={Object.keys(ideaStepFlux.visualizationConfigs).find(key => ideaStepFlux.visualizationConfigs[key])}
                      onChange={onChangeVisualization}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        value="showInEvaluationTab"
                        label={t('visualizar_guia_de_avaliacao')}
                      />
                      <FormControlLabel
                        disabled={ideaStepFlux.returnTheIdeaToAnotherStage}
                        control={<Radio />}
                        value="showInExecutionTab"
                        label={t('visualizar_guia_de_execucao')}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        value="showInValidationTab"
                        label={t('visualizar_guia_de_validacao')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Card>
              </Grid>
            </Grid>

            <Grid container className={classes.marginTopHigh}>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.card}>
                  <Typography className={classes.stateTitle}>
                    {t('selecione_niveis_de_notificacao')}
                  </Typography>
                  <CheckboxCustom
                    name="notifyDirectManagerWhenDecision"
                    checked={ideaStepFlux.notificationsControl?.notifyDirectManagerWhenDecision}
                    onChange={onChangeSwitchNotification}
                    label={t('notificar_gestor_direto')}
                  />
                  <CheckboxCustom
                    name="notifyAreaManagersWhenDecision"
                    checked={ideaStepFlux.notificationsControl?.notifyAreaManagersWhenDecision}
                    onChange={onChangeSwitchNotification}
                    label={t('notificar_gestor_da_area')}
                  />
                  <CheckboxCustom
                    name="notifyEvaluationTeamsWhenDecision"
                    checked={ideaStepFlux.notificationsControl?.notifyEvaluationTeamsWhenDecision}
                    onChange={onChangeSwitchNotification}
                    label={t('notificar_time_de_avaliacao')}
                  />
                  <CheckboxCustom
                    name="notifyIdeaContributorsWhenDecision"
                    checked={ideaStepFlux.notificationsControl?.notifyIdeaContributorsWhenDecision}
                    onChange={onChangeSwitchNotification}
                    label={t('notificar_contribuidores_ideia')}
                  />
                  <CheckboxCustom
                    name="notifyEvaluatorsWhenNewPending"
                    checked={ideaStepFlux.notificationsControl?.notifyEvaluatorsWhenNewPending}
                    onChange={onChangeSwitchNotification}
                    label={t('notificar_avaliadores_ideia')}
                  />
                </Card>
              </Grid>

              <Grid item xs={12} className={classes.marginTopHigh}>
                {ideaStepFlux.loading ? <LoadingButton /> : (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    onClick={() => isEdit ? setConfirmEditModal(true) : handleSend()}
                    disabled={validateDisabled()}
                  >
                    {isEdit ? t('update_idea_step_flux') : t('save_step')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

export { IdeaStepFluxNewPage };