import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import EventIcon from '@material-ui/icons/Event';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import { Card, Caption, DateAndTimeContainer } from './styles';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';
import { AvatarWithoutPhoto } from '../../Shared';
import moment from 'moment';

export function HistoryCardInitial() {
  const { t } = useTranslation();
  const { ideaDetailsFlux } = useIdeaDetailsController();
  
  return (
    <Grid container spacing={3}>
      {ideaDetailsFlux?.evaluationStepsHistory.map((item) => (
        <Grid item xs={12} key={item._id}>
          <Card elevation={0}>
            <Grid container spacing={3} style={{ padding: '32px 32px 20px' }}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <SubdirectoryArrowRightIcon color="primary" />
                  <Typography variant="subtitle1" style={{ margin: '0px 10px' }}>
                    {t('etapa')}:
                  </Typography>
                  <Typography variant="subtitle1">{item?.observation}</Typography>
                </Box>
              </Grid>
              {item?.evaluator && (
                <Grid item xs={12}>       
                  <Box display="flex">
                    <AvatarWithoutPhoto            
                      userName={item.evaluator.name}
                      src={item.evaluator?.attachment?.url}
                    />
                    <Box display="flex" flexDirection="column" style={{ marginLeft: 15, gap: 8 }}>
                      <Box>
                        <Typography variant="subtitle2">{(item.evaluator.name)}</Typography>
                        <Caption variant="caption">{(item.evaluator.position)}</Caption>
                      </Box>

                      <Box display="flex" alignItems="center" style={{ gap: 22 }}>
                        <DateAndTimeContainer>
                          <EventIcon fontSize="small" />
                          <Caption variant="caption">
                            {moment(item.evaluatedAt).format('DD/MM/YYYY')}
                          </Caption>
                        </DateAndTimeContainer>
                        <DateAndTimeContainer>
                          <QueryBuilderIcon fontSize="small" />
                          <Caption variant="caption">
                            {moment.utc(item.evaluatedAt).format('HH:mm')}
                          </Caption>
                        </DateAndTimeContainer>
                      </Box>

                      <Typography variant="body2">{item?.observation}</Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      ))[0]}
    </Grid>
  );
}