import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { PrivateRoute, PublicRoute, AdminRoute } from './components';
import { ExternalContentsPage } from './containers/ExternalContentsPage';
import { ExternalContentsForm } from './containers/ExternalContentsForm';
import { LoginPage } from './containers/LoginPage';
import { LogoutPage } from './containers/LogoutPage';
import { DashboardPage } from './containers/DashboardPage';
import { HomePage } from './containers/HomePage';
import { IdeaPage } from './containers/IdeaPage';
import { IdeaPointPage } from './containers/IdeaPointPage';
import { PerfilPage } from './containers/PerfilPage';
import { SettingsPage } from './containers/SettingsPage';
import { IdeaEditPage } from './containers/IdeaEditPage';
import { IdeaCanvasPage } from './containers/IdeaCanvasPage';
import { RegisterPage } from './containers/RegisterPage';
import { ProfilePage } from './containers/ProfilePage';
import { WalletPage } from './containers/WalletPage';
import { RankingPage } from './containers/RankingPage';
import { ContentsPage } from './containers/ContentsPage';
import { ChallengePage } from './containers/ChallengePage';
import { ChallengeNewPage } from './containers/ChallengeNewPage';
import { ChallengeDetailPage } from './containers/ChallengeDetailPage';
import { ChallengeEditPage } from './containers/ChallengeEditPage';
import { ManagerRoute } from './components/Routes/ManagerRoute';
import { RecoverPasswordPage } from './containers/RecoverPasswordPage';
import { ResetPasswordPage } from './containers/ResetPasswordPage';
import { UsersPage } from './containers/UsersPage'
import { UserFunctionPage } from "./containers/UserFunctionPage"
import { RegulationPage } from './containers/RegulationPage';
import { HelpCenterPage } from './containers/HelpCenterPage';
import { TermsPage } from './containers/TermsPage';
import { InviteLeadershipPage } from './containers/InviteLeadership';
import { InvitePage } from './containers/Invite';
import { ContentsDetailsPage } from './containers/ContentsDetailsPage';
import { PremiarPage } from './containers/PremiarPage';
import { ReportStatusIdea } from "./containers/ReportStatusIdea"
import { ReportStatusIdeaPendingManagers } from "./containers/ReportStatusIdeaPendingManagers"
import { ReportCustoRetorno } from "./containers/ReportCustoRetorno"
import { ReportColaborator } from "./containers/ReportColaborator"
import { CurationIdeasMatPage } from './containers/CurationIdeasMatPage/CurationIdeasMatPage';
import { IdeaDetailsPage } from "./containers/IdeaDetailsPage";
import { FaqPage } from "./containers/FaqPage";
import { AdminUserEditPage } from "./containers/AdminUserEditPage";
import { UserPoints } from "./containers/UserPoints";
import { PrivacyPolicyPage } from './containers/PrivacyPolicyPage';
import { InfraestructurePage } from "./containers/InfraestructurePage";
import { PersonalizedClassificationPage } from "./containers/PersonalizedClassificationPage";
import { PersonalizedClassificationListPage } from "./containers/PersonalizedClassificationListPage";
import { ReportAccessColaborator } from './containers/ReportAccessColaborator';
import { ReportManager } from './containers/ReportManager/ReportManager';
import { PortfolioPage } from "./containers/PortfolioPage"
import { PortfolioDetailsPage } from "./containers/PortfolioDetailsPage"
import { AdminUserEditLayout } from './containers/AdminUserEditLayout';
import { FeedbackPage } from './containers/FeedbackPage';
import { HelpCenterEdit } from './containers/HelpCenterEdit/HelpCenterEdit';
import { EvaluationTeamsPage } from './containers/EvaluationTeamsPage';
import { GeneralEvaluationPage } from './containers/GeneralEvaluationPage';
import { GeneralEvaluationEditPage } from './containers/GeneralEvaluationEditPage';
import { GeneralEvaluationDetailsPage } from './containers/GeneralEvaluationDetailsPage';
import { InfraAssociationsPage } from './containers/InfraAssociationsPage/InfraAssociationsPage';
import config from './config';
import { ReportAccessColaboratorByMonth } from './containers/ReportAccessColaboratorByMonth';
import { ReportIdeasByStepDecision } from './containers/ReportIdeasByStepDecision';
import { NotificationPage } from './containers/NotificationPage';
import { ArticlesPage } from './containers/ArticlesPage';
import { ArticleNewPage } from './containers/ArticleNewPage';
import { ArticleDetailPage } from './containers/ArticleDetailPage';
import { ArticleEditPage } from './containers/ArticleEditPage';
import { ReportIdeasWithEvaluatedSteps } from './containers/ReportIdeasWithEvaluatedSteps';
import { FluxOfIdeasPage } from './containers/FluxOfIdeasPage';
import { IdeaStepFluxNewPage } from './containers/IdeaStepFluxNewPage/IdeaStepFluxNewPage';
import { IdeaStepFluxEditAssociationsPage } from './containers/IdeaStepFluxEditAssociationsPage/IdeaStepFluxEditAssociationsPage';

function Routes() {
  return (
    <LastLocationProvider>
      <Switch>
        <PrivateRoute path="/help-center-edit" component={HelpCenterEdit} />     
        <PrivateRoute path="/preferences/evaluation-teams" component={EvaluationTeamsPage} />
        {/* <PrivateRoute exact path="/crud/:name" component={TemplateCrudPage} /> */}
        {/* <PrivateRoute exact path="/template-crud/new" component={TemplateCrudNewPage} />
        <PrivateRoute exact path="/template-crud/:id" component={TemplateCrudEditPage} /> */}
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute path="/idea/point" component={IdeaPointPage} />
        <PrivateRoute path="/idea/:id/:page" exact component={IdeaDetailsPage} />
        <PrivateRoute path="/idea/:id/canvas" exact component={IdeaCanvasPage} />
        <ManagerRoute path="/curation" exact component={CurationIdeasMatPage} />
        <PrivateRoute path="/portfolio" exact component={PortfolioPage} />
        <PrivateRoute path="/portfolio/:id" exact component={PortfolioDetailsPage} />
        <PrivateRoute path="/my-ideas" exact component={IdeaPage} />
        <PrivateRoute path="/idea-edit/:id" exact component={IdeaEditPage} />
        <PrivateRoute path="/user/edit" exact component={PerfilPage} />
        <PrivateRoute path="/user/settings" exact component={SettingsPage} />
        <PrivateRoute path="/profile/:id" component={ProfilePage} />
        <PrivateRoute path="/wallet/:id" component={WalletPage} />
        <PrivateRoute path="/ranking" component={RankingPage} />
        <PrivateRoute path="/contents" component={ContentsPage} />
        <PrivateRoute path="/contents-details" component={ContentsDetailsPage} />
        <PrivateRoute path="/premiar-page" component={PremiarPage} />
        <PrivateRoute  path="/feedbacks" component={FeedbackPage} />
        <PrivateRoute  path="/notifications" component={NotificationPage} />
        <AdminRoute path="/external-contents" component={ExternalContentsPage} />
        <AdminRoute path="/articles" component={ArticlesPage} />
        <AdminRoute path="/external-content/new" component={ExternalContentsForm} />
        <AdminRoute path="/external-content/edit/:id" component={ExternalContentsForm} />
        <AdminRoute path="/infrastructure" exact component={InfraestructurePage} />
        <PrivateRoute path="/challenges" component={ChallengePage} />
        <PrivateRoute path="/users" exact component={UsersPage} />
        <AdminRoute path="/users/admin-edit/:id" exact component={AdminUserEditLayout} />
        <PrivateRoute path="/help-center" component={HelpCenterPage} />
        <PrivateRoute path="/faq" component={FaqPage} />
        <PrivateRoute path="/challenge/detail/:id" exact component={ChallengeDetailPage} />
        <PrivateRoute path="/article/detail/:id" exact component={ArticleDetailPage} />
        <AdminRoute path="/challenge/new" exact component={ChallengeNewPage} />
        <AdminRoute path="/article/new" exact component={ArticleNewPage} />
        <AdminRoute path="/article/edit/:id" exact component={ArticleEditPage} />
        <AdminRoute path="/challenge/edit/:id" exact component={ChallengeEditPage} />
        <PublicRoute path="/confirm-password-recover" component={ResetPasswordPage} />
        <PublicRoute path="/password-recover" component={RecoverPasswordPage} />
        <ManagerRoute path="/report/ideas-with-evaluated-steps" exact component={ReportIdeasWithEvaluatedSteps} />
        <ManagerRoute path="/report/ideas-by-step" exact component={ReportStatusIdea} />
        <ManagerRoute path="/report/ideas-by-evaluated-step-decision" exact component={ReportIdeasByStepDecision} />
        <ManagerRoute path="/report/ideas-status" exact component={ReportStatusIdea} />
        <ManagerRoute path="/report/ideas-status-pending-managers" exact component={ReportStatusIdeaPendingManagers} />
        <ManagerRoute path="/report/ideas-financial-return" exact component={ReportCustoRetorno} />
        <ManagerRoute path="/report/colaborator" exact component={ReportColaborator} />
        <ManagerRoute path="/report/colaborator-access" exact component={ReportAccessColaborator} />
        <ManagerRoute path="/preferences/personalized-classification" exact component={PersonalizedClassificationListPage} />
        <ManagerRoute path="/preferences/personalized-classification/edit" exact component={PersonalizedClassificationPage} />
        <AdminRoute path="/preferences/general-evaluation" exact component={GeneralEvaluationPage} />
        <AdminRoute path="/preferences/general-evaluation/create-evaluation" exact component={GeneralEvaluationEditPage} />
        <AdminRoute path="/preferences/general-evaluation/:id" exact component={GeneralEvaluationDetailsPage} />
        <AdminRoute path="/preferences/general-evaluation/:id/edit" exact component={GeneralEvaluationEditPage} />
        <AdminRoute path="/preferences/flux-of-ideas" exact component={FluxOfIdeasPage} />
        <AdminRoute path="/preferences/flux-of-ideas/new" exact component={IdeaStepFluxNewPage} />
        <AdminRoute path="/preferences/flux-of-ideas/update/:id" exact component={IdeaStepFluxNewPage} />
        <AdminRoute path="/preferences/flux-of-ideas/edit/associations/:id" exact component={IdeaStepFluxEditAssociationsPage} />
        <ManagerRoute path="/report/managers-list" exact component={ReportManager} />
        <Route path="/regulation" component={RegulationPage} />
        <Route path="/terms" component={TermsPage} />
        <Route exact path="/custom-privacy-policy" component={PrivacyPolicyPage} />
        <AdminRoute path="/dashboard" exact component={DashboardPage} />
        <PublicRoute path="/register" exact component={RegisterPage} />
        <PublicRoute path="/login" component={LoginPage} />
        <PublicRoute path="/convitelideres" component={InviteLeadershipPage} exact />
        <PublicRoute path="/convite" component={InvitePage} exact />
        <PrivateRoute path="/logout" component={LogoutPage} />
        <PrivateRoute path="/infrastructure/associations/:id" component={InfraAssociationsPage} />
        <ManagerRoute path="/report/colaborator-access-by-month" exact component={ReportAccessColaboratorByMonth} />
        <Redirect from="*" to="/" />
      </Switch>
    </LastLocationProvider>
  );
}

export { Routes };
